//@import "darkreader";
body {
  margin: 0;
  background: #222;
  color: #f0f0f0;
  font-family: 'Roboto', sans-serif;
}

@media only screen and (orientation: portrait ) {
  :root .Buttons {
    position: fixed !important;
    top: unset!important;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
:root .Buttons {
  background-color: #333 !important;

  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;

  .paginator menu {
    font-family: Helvetica, sans-serif;
    li {
      display: inline;
      list-style-type: none;
      color: #f2f2f2;
      padding: 0 0.2em;
      margin: 0;
    }

    .subtitle {
      color: #737373;
    }
    a {
      text-decoration: none;
      color: hsl(200, 100%, 80%);
    }
  }
  .center {
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: inline-block;
      font-family: Helvetica, sans-serif;
      color: #fff;
      font-weight: bolder;
      text-shadow: #fff 0 0 5px;
    }
  }
  button {
    height: 100%;
    padding: 3px 6px;
    margin: auto 20px;
    background-color: black;
    color: white;
  }
}

//https://coomer.party/banners/onlyfans/belledelphine
//chat gpt given style
.vids {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  padding: 20px;
  .error {
    background-color: #ff4d4d;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
  }
  video {
    width: 100%;
    height: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: all 0.3s ease;
  }

  div {
    position: relative;

    button {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #fff;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      opacity: 0;
      visibility: hidden;
    }

    &:hover button {
      opacity: 1;
      visibility: visible;
    }
  }
}

